import './App.css';
import {useState} from "react";
import {searchResult} from "./script/script";
import {TrashIcon} from "@heroicons/react/20/solid";

function App() {

    const [data, setData] = useState("");
    const [image, setImage] = useState("");
    const [text, setText] = useState("");
    const [isImage, setStatus] = useState(false);

    const getData = (e) => {
        e.preventDefault()
        const result = searchResult(text)
        if (result.includes("img=https")){
            setStatus(true)
            setImage(result.substring(4))
            setData("")
        } else {
            setStatus(false)
            setData(result)
            setImage("")
        }
        setText("")
    }

    return (
        <div className={"min-h-screen flex flex-col items-center py-10 gap-5"}>
            <h1 className={"text-xl sm:text-3xl font-bold"}>Topolino</h1>
            <div className={"w-4/5 flex flex-col gap-2"}>
                <div className="mt-1">
                        <textarea
                            name="text"
                            className="block h-72 w-full rounded-md border-2 border-gray-300 shadow-sm p-2 outline-none"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            placeholder={"Inserisci qui la domanda"}
                        />
                </div>
                <div className={"flex flex-col gap-2 sm:flex-row w-full justify-center"}>
                    <button
                        type="button"
                        onClick={getData}
                        className="flex-1 flex justify-evenly items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Invia
                    </button>
                    <div className={"flex-1 hidden sm:block"}/>

                    <button
                        type="button"
                        onClick={() => setText("")}
                        className="flex-1 flex justify-evenly items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <TrashIcon className={"h-5"}/>
                    </button>
                </div>

            </div>

            <div className={"bg-white border-2 w-4/5 px-4 py-2 rounded"}>
                <p className={"text-xl font-bold"}>Risultato</p>
                { isImage ? <img className="text w-4/5 h-4/5" src={image} alt=""/> : <p className={"text"}>{data}</p> }
            </div>
        </div>
    );
}

export default App;

