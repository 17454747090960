import data from "./data.json";
export const searchResult = (question) => {
    let text = question.replace("Scegli un'alternativa:", "");
    text = text.replace(/\n/g, '');
    text = text.replace(/ /g, '');
    let result = "Nessuna risposta trovata";

    for (let i = 0; i < data.length; i++) {
        const quest = data[i].Quest.replace(/ /g, '');
        if (quest.includes(text)) {
            result = data[i].Answers[data[i].Correct];
            break;
        }
    }

    return result;
}
